<template>
  <v-card>
    <div class="tw-px-2 md:tw-px-6">
      <card-title icon="mdi-clipboard-text-outline" :is-image="false"
        >Assessment Centre Updates</card-title
      >
    </div>

    <div class="tw-px-2 md:tw-px-6 tw-py-10">
      <div
        class="
          tw-grid tw-grid-cols-1
          sm:tw-grid-cols-2
          md:tw-grid-cols-3
          lg:tw-grid-cols-4
          tw-gap-8
        "
      >
        <summary-card
          v-for="(item, index) in summaries"
          :key="index"
          :count="item.count"
          :title="item.title"
          :category="item.category"
          :loading="isLoading"
        ></summary-card>
      </div>
    </div>

    <div class="tw-px-2 md:tw-px-4">
      <v-divider class="border-opacity-75"></v-divider>

      <div class="tw-flex tw-items-center tw-gap-2 tw-p-2">
        <v-icon class="c-gray-text" size="large">mdi-clock-time-four</v-icon>

        <p class="mb-0 c-gray-text tw-text-sm">Just Updated</p>
      </div>
    </div>
  </v-card>
</template>

<script>
import CardTitle from '@/components/CardTitle'

export default {
  components: {
    CardTitle,
    SummaryCard: () => import('@/components/dashboard/SummaryCard'),
  },
  props: {
    summary: {
      type: Object,
      required: true,
      default: () => ({
        bookedVehicles: 0,
        supplementaryReceived: 0,
        submittedBids: 0,
        approvedBids: 0,
        writeOf: 0,
        belowExcess: 0,
        cashInLieu: 0,
        declined: 0,
        undocumented: 0,
        underInvestigation: 0,
        checkedOut: 0,
      }),
    },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    summaries() {
      return [
        {
          count: this.summary.bookedVehicles,
          title: 'No. of Vehicles Booked',
          category: 'VEHICLES_BOOKED',
        },
        {
          count: this.summary.submittedBids,
          title: 'No. of Bid Received',
          category: 'BIDS_SUBMITTED',
        },
        {
          count: this.summary.supplementaryReceived,
          title: 'Supplementaries Received',
          category: 'SUPPLEMENTARY_RECEIVED',
        },
        {
          count: this.summary.approvedBids,
          title: 'No. of Approved Bids',
          category: 'APPROVED_BIDS',
        },
        {
          count: this.summary.cashInLieu,
          title: 'No. of Cash in Lieu',
          category: 'CASH_IN_LIEU',
        },
        {
          count: this.summary.belowExcess,
          title: 'No. of Below Excess',
          category: 'BELOW_EXCESS',
        },
        {
          count: this.summary.writeOf,
          title: 'No. of Write Offs',
          category: 'WRITE_OFF',
        },
        {
          count: this.summary.declined,
          title: 'Declined Claims',
          category: 'DECLINED',
        },
        {
          count: this.summary.underInvestigation,
          title: 'Vehicles under investigation',
          category: 'UNDER_INVESTIGATIONS',
        },
        {
          count: this.summary.undocumented,
          title: 'Undocumented Claims',
          category: 'UNDOCUMENTED',
        },
        {
          count: this.summary.checkedOut,
          title: 'No. of Checked Out Vehicles',
          category: 'CHECKED_OUT',
        },
      ]
    },
  },
}
</script>
